import React, { useEffect, useState } from "react";

import {
    Container, 
    Header,
    LogsContainer,
    LogItem,
    ListHeader,
    ModalContainer,
    SubHeader,
    ModalListHeader,
    ModalLogItem,
    ModalListContainer,
    LogsListContainer
} from './styles';

//Icons
import { FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import { AiOutlineEye, AiOutlineClockCircle, AiOutlineUser } from 'react-icons/ai';
import { TbCalendar } from 'react-icons/tb';
import cpfIcon from '../../assets/cpfIcon.svg';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom Components
import ProfileButton from "../../components/ProfileButton";
import CustomLoadingPage from "../../components/CustomLoadingPage";
import ModalHeader from "../../components/ModalHeader";
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { TableFooter } from "../../components/CustomTable/styles";

//Utils
import Modal from 'react-modal';
import CustomDivider from "../../components/CustomDivider";
import { datetime } from "../../helpers/datetime";
import { getPjeLogs } from "../../firebase/logs/pje/getPjeLogs";

const perPage = 40;

const PjeLogsPage = () => {
    const [ pjeLogs, setPjeLogs ] = useState('');
    const [ loadingData, setLoadingData ] = useState(true);

    const [ logs, setLogs ] = useState('');
    const [ logsBackup, setLogsBackup ] = useState('');

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    //Modal
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ modalData, setModalData ] = useState('');

    useEffect(() => {
        (async () => {
            const logs = await getPjeLogs();

            setPjeLogs(logs);
        })();
    }, [])
    
    useEffect(() => {
        (async () => {
            if(pjeLogs){
                if(pjeLogs.length == 0){
                    return alert('Sem logs')
                }
                
                setLogs(pjeLogs);
                setLogsBackup(pjeLogs)
                setLoadingData(false);
            }
        })();
    }, [pjeLogs]);

    if(loadingData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <h1>Log PJE BOT</h1>

                <div>
                    <CustomFilterHeader
                        tableRowsBackup={logsBackup}
                        data={logs}
                        filterTypes={[
                            { headerName: 'Data', field: 'timeStart' },
                        ]}
                        onFilter={(newData, values) => {
                            setLogs(newData)
                        }}
                        clearFilters={() => {
                            setLogs(logsBackup)
                        }}
                        filterType='atendimentos'
                        dateField='timeStart'
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            
            <LogsContainer>
                <ListHeader>
                    <h3>Data</h3>
                    <h3>Hora</h3>
                    <h3>Status</h3>
                    <h3>Tempo de operação</h3>
                    <h3>Clientes atualizados</h3>
                    <h3>Clientes não atualizados</h3>
                    <h3>Visualizar</h3>
                </ListHeader>
                <LogsListContainer>
                    {logs && logs.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                        <div key={index} >
                            <LogItem color={item.success ? 'var(--aqua)' : 'var(--red)'}>
                                <h4>{datetime.getDate(item.timeStart)}</h4>
                                <h4>{datetime.getHours(item.timeStart)}</h4>
                                <div>
                                    {item.success
                                        ? <FiCheckCircle />
                                        : <FiAlertCircle />
                                    }
                                    <h4>{item.success ? 'Bem sucedida' : 'Mal sucedida'}</h4>
                                </div>
                                <h4>{Math.floor((item.timeEnd - item.timeStart)/60000)} min</h4>
                                <h4>{item.UpdatedClients}</h4>
                                <h4>{item.notUpdatedClients}</h4>
                                <AiOutlineEye 
                                    onClick={() => {
                                        setModalData(item);
                                        setModalVisible(true);
                                    }} 
                                    cursor='pointer' 
                                    size={20} 
                                    color='#808B9F' 
                                    fill='#808B9F'
                                />
                            </LogItem>
                            {index < logs.length && (<CustomDivider width='98%'/>)}
                        </div>
                    ))}
                </LogsListContainer>
                <TableFooter>
                    <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                    <div>
                        <MdKeyboardArrowLeft 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(rangeOfList.finish == logs.length){
                                    const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                    const newFinish = rangeOfList.init;

                                    setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                }else{
                                    if((rangeOfList.init - perPage) >= 0){
                                        setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                    }
                                }
                            }}
                        />
                        <MdKeyboardArrowRight 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(logs.length > perPage){
                                    if((rangeOfList.finish + perPage) <= logs.length){
                                        setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                    }else{
                                        if(rangeOfList.finish != logs.length){
                                            const value = rangeOfList.finish+(logs.length - rangeOfList.finish);
        
                                            setRangeOfList({ init: rangeOfList.finish, finish: value})
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <h3>{logs ? logs.length : 0} clientes</h3>
                </TableFooter>
            </LogsContainer>

            <Modal
                isOpen={modalVisible}
                onRequestClose={() => {
                    setModalVisible(false)
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalContainer>
                    <ModalHeader title='Detalhes BOT PJE' onClose={() => {
                        setModalVisible(false)
                    }}/>
                    <SubHeader>
                        <div>
                            <TbCalendar />
                            <h4>{datetime.getDate(modalData.timeStart)}</h4>
                        </div>
                        <div>
                            <AiOutlineClockCircle />
                            <h4>{datetime.getHours(modalData.timeStart)}</h4>
                        </div>
                        <div>
                            {modalData.success
                                ? <FiCheckCircle color='var(--aqua)' />
                                : <FiAlertCircle color='var(--red)' />
                            }
                            <h4>{modalData.success ? 'Bem sucedida' : 'Mal sucedida'}</h4>
                        </div>
                        <div>
                            <AiOutlineUser />
                            <h4>{modalData.UpdatedClients}</h4>
                        </div>
                    </SubHeader>
                    <ModalListHeader>
                        <h3>Nome</h3>
                        <h3>CPF</h3>
                        <h3>Processo</h3>
                        <h3>Data da retirada</h3>
                    </ModalListHeader>
                    <ModalListContainer>
                        {modalData.updatedClientsList && modalData.updatedClientsList.map((item, index) => (
                            <div key={index}>
                                <ModalLogItem key={index}>
                                    <h4>{item.nome}</h4>
                                    <div>
                                        <img src={cpfIcon} alt='cpf' />
                                        <h4>{item.cpf}</h4>
                                    </div>
                                    <h4>{item.processo}</h4>
                                    <h4>{datetime.getFullDate(modalData.timeStart)}</h4>
                                </ModalLogItem>
                                {index < modalData.updatedClientsList.length && (<CustomDivider width='98%'/>)}
                            </div>
                        ))}
                    </ModalListContainer>
                </ModalContainer>
            </Modal>
        </Container>
    )
}

export default PjeLogsPage;